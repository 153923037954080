<template>
  <div class="contact my-10">
    <v-container class="my-10 update">
      <v-card class="box-form py-2 px-4">
        <h2 class="my-2 center">Meus dados</h2>

        <v-form v-model="validity" ref="update">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="form.name"
                name="name"
                type="text"
                label="Nome"
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="form.cpf"
                name="cpf"
                type="text"
                label="CPF"
                outlined
                v-mask="['###.###.###-##', '###.###.###-##']"
                :rules="[rules.required, rules.cpf]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="form.email"
                name="email"
                type="text"
                label="Email"
                outlined
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.password"
                name="password"
                type="password"
                label="Senha"
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col class="my-2 text-right">
              <v-btn color="primary" @click="send">Cadastrar</v-btn>
            </v-col>
          </v-row>
          <v-text-field
            v-model="form.id"
            hide-details
            name="code"
            type="hidden"
          ></v-text-field>
          <v-alert
            v-model="alert"
            dismissible
            close-icon="mdi-delete"
            color="green"
            border="left"
            elevation="2"
            colored-border
            icon="fa fa-exclamation-triangle"
            class="alert-float"
          >
            {{ msg }}
          </v-alert>
          <v-alert
            class="contact-alert"
            v-if="error"
            :icon="false"
            type="error"
            style="font-size: 14px;"
            >{{ msg }}</v-alert
          >
        </v-form>
        <v-overlay :value="loading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { cpf, email } from "@/inputRules";

export default {
  data: () => ({
    validity: false,
    form: { id: "", name: "", email: "", cpf: "", password: "" },
    msg: "",
    sit: false,
    rules: {
      required: (v) => !!v || "Requerido.",

      email,
      cpf,
    },
    loading: false,
    error: false,
    alert: false,
  }),
  computed: {
    server() {
      return this.$store.state.server;
    },
    social() {
      return this.$store.state.holder.social;
    },
    map() {
      return this.$store.state.holder.map;
    },
  },
  methods: {
    send() {
      if (this.$refs.update.validate()) {
        this.loading = true;
        this.error = false;

        fetch(this.$store.state.server + "/v1/registeruser", {
          method: "POST",
          body: new FormData(this.$refs.update.$el),
        })
          .then(async (response) => {
            let data = await response.json();
            if (data.sit == true) {
              this.alert = true;
              this.msg = data.msg;
              setTimeout(function() {
                window.location.href = "/area-restrita";
              }, 2000);
            } else {
              this.error = true;
              this.msg = data.msg;
            }
          })
          .catch(() => (this.error = true))
          .finally(() => (this.loading = false));
      }
    },
  },
  components: {},
  directives: {
    mask,
  },
};
</script>

<style>
.update {
  margin: 0 auto;
  width: 350px;
}
</style>

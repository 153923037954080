<template>
  <v-container class="materias py-12">
    <v-row class="py-6">
      <v-col md="4" v-for="(materias, i) in courses" :key="i">
        <v-card class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">{{ materias.title }}</v-list-item-title>
              <v-list-item-subtitle>Estude os conteúdos e faça uma simulação da prova.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn color="primary" :to="'/materias/'+materias.id" depressed>Ver apostila</v-btn>
            <v-spacer></v-spacer>
            <v-btn :to="'/simulado/'+materias.id" color="yellow accent-4" depressed>Fazer simulado</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    courses() {
      return this.$rest("courses").list;
    }
  },
  beforeCreate() {
    const token = window.localStorage.getItem("token");
    if (token) {
      this.$rest("courses").get();
    } else {
      window.location.href = "/area-restrita";
    }
  }
};
</script>
<style>
.simulated img {
  display: inline-block;
  max-width: 100%;
}
.simulated > div {
  display: inline-block;
  width: 100%;
}
</style>

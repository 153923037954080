<template>
  <div v-if="!tokenverif" class="form-centered px-4">
    <v-card class="box-form">
      <v-toolbar class="text-center" dark flat>
        <v-toolbar-title class="mx-auto">Acessar minha área</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="text-left">
        <v-form ref="login">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.email"
                name="email"
                type="text"
                label="Email"
                hide-details
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.password"
                name="password"
                type="password"
                label="Senha"
                hide-details
                outlined
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </v-col>
            <v-col class="my-2">
              <v-card-actions>
                <v-btn :to="'/cadastro'">Cadastrar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="send">Entrar</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-alert
      class="contact-alert"
      v-if="success"
      :icon="false"
      type="success"
      style="font-size: 14px;"
      >Sua mensagem foi enviada com sucesso.</v-alert
    >

    <v-alert
      class="contact-alert"
      v-if="error"
      :icon="false"
      type="error"
      style="font-size: 14px;"
      >Aconteceu um erro ao enviar a mensagem.</v-alert
    >
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
  <div v-else class="form-centered px-4 center">
    <h4 class="center">CLIQUE NO MENU ABAIXO PARA NAVEGAR ENTRE AS TELAS</h4>
    <v-icon class="py-4" large color="green darken-2">fa-hand-o-down</v-icon>
  </div>
</template>

<script>
// import Api from "../services/Api";

export default {
  data: () => ({
    form: { email: "", password: "" },
    rules: {
      required: v => !!v || "Requerido.",
      email: v =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail inválido."
    },
    loading: false,
    error: false,
    success: false
  }),
  computed: {
    tokenverif() {
      return window.localStorage.getItem("token");
    }
  },
  methods: {
    openFile(file) {
      window.open(file);
    },
    send() {
      this.loading = true;
      this.error = false;
      this.success = false;

      fetch(this.$store.state.server + "/v1/login", {
        method: "POST",
        body: new FormData(this.$refs.login.$el)
      })
        .then(r => r.json())
        .then(r => {
          if (r.login === true) {
            window.localStorage.setItem("token", r.token);
            window.localStorage.setItem("code", r.code);
            window.location.href = "/materias";
          } else {
            throw new Error(r.status);
          }
        })
        .catch(() => (this.error = true))
        .finally(() => (this.loading = false));
    }
  },
  mounted() {},
  beforeCreate() {
    // this.$rest("download").get();
  }
};
</script>

<style>
.form-centered {
  margin: 10% auto;
  width: 350px;
}
</style>

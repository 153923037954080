const required = (v) => !!v || "Obrigatório.";

const email = (v) =>
  /^[a-zA-Z0-9.!#$%&'*+/\\=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
    v
  ) || "E-mail inválido.";

const cpf = (cpf) => {
  try {
    if (typeof cpf !== "string") throw "CPF inválido.";

    const pattern = /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/m.test(cpf);

    cpf = cpf.replace(/[^0-9]/g, "");
    const repeat = /^(0{11}|1{11}|2{11}|3{11}|4{11}|5{11}|6{11}|7{11}|8{11}|9{11})$/.test(
      cpf
    );

    if (!pattern || repeat) throw "CPF inválido.";
  } catch (e) {
    return e;
  }

  const arr = cpf
      .substr(0, 9)
      .split("")
      .reverse(),
    dV1 = cpf.substr(9, 1),
    dV2 = cpf.substr(10, 1);

  let rV1 =
      (arr.reduce((r, v, i) => r + parseInt(v) * (9 - (i % 10)), 0) % 11) % 10,
    rV2 =
      ((arr.reduce((r, v, i) => r + parseInt(v) * (9 - ((i + 1) % 10)), 0) +
        rV1 * 9) %
        11) %
      10;

  if (dV1 != rV1 || dV2 != rV2) {
    return "Algum dos digitos verificadores está incorreto.";
  }

  return true;
};

const url = (v) => {
  if (!v) return true;
  else
    return (
      /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/.test(
        v
      ) || "URL inválida."
    );
};

/**
 * Verificar se date é um objeto `Date` ou se possível criar um usando date como parâmetro.
 * @param {string | Date} date
 */
const verifyDate = (date) => {
  if (typeof date === "string") return new Date(date);
  else if (date instanceof Date) return date;
  else throw new Error(`${date} não é um objeto data ou uma string.`);
};

const RTF = new Intl.RelativeTimeFormat("pt-BR", { numeric: "auto" });
/**
 * Transformar a data d no formato local.
 * @param {string | date} d
 */
const toLocaleDate = (d) => {
  d = verifyDate(d);
  const hj = new Date();
  let diff = dateDiff(hj, d, "days");

  if (Math.abs(diff) > 30) {
    return RTF.format(diff, "month");
  } else if (Math.abs(diff) > 0) {
    return RTF.format(diff, "day");
  }

  diff = dateDiff(hj, d, "hours");
  if (Math.abs(diff) > 1) return RTF.format(diff, "hour");

  diff = dateDiff(hj, d, "minutes");
  if (Math.abs(diff) > 1) return RTF.format(diff, "minute");

  diff = dateDiff(hj, d, "seconds");
  if (Math.abs(diff) > 0) return RTF.format(diff, "second");

  return "agora";
};

const _MS_PER_MIN = 1000 * 60;
const _MS_PER_HOUR = _MS_PER_MIN * 60;
const _MS_PER_DAY = _MS_PER_HOUR * 24;
const _UTC_DAY = ["getFullYear", "getMonth", "getDate"];
const _UTC_HOUR = [..._UTC_DAY, "getHours"];
const _UTC_MIN = [..._UTC_HOUR, "getMinutes"];
const _UTC_SEC = [..._UTC_MIN, "getSeconds"];
const _utcSec = (d) => Date.UTC(..._UTC_SEC.map((f) => d[f]()));
const _utcMin = (d) => Date.UTC(..._UTC_MIN.map((f) => d[f]()));
const _utcHour = (d) => Date.UTC(..._UTC_HOUR.map((f) => d[f]()));
const _utcDay = (d) => Date.UTC(..._UTC_DAY.map((f) => d[f]()));

/**
 * Obter a diferença entre duas data de acordo com o parâmetro type.
 *
 * @param {Date} d1 Subtraendo
 * @param {Date} d2 Minuendo
 * @param {string} type minutes | hours | days
 */
const dateDiff = (d1, d2, type = "days") => {
  let div, utc1, utc2;

  switch (type) {
    case "seconds":
      utc1 = _utcSec(d1);
      utc2 = _utcSec(d2);
      div = 1000;
      break;
    case "minutes":
      utc1 = _utcMin(d1);
      utc2 = _utcMin(d2);
      div = _MS_PER_MIN;
      break;
    case "hours":
      utc1 = _utcHour(d1);
      utc2 = _utcHour(d2);
      div = _MS_PER_HOUR;
      break;
    case "days":
      utc1 = _utcDay(d1);
      utc2 = _utcDay(d2);
      div = _MS_PER_DAY;
      break;
  }

  return (utc2 - utc1) / div;
};

export { required, email, cpf, url, toLocaleDate };

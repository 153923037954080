<template>
  <router-link :to="to">
    <div class="row institutionalcard">
      <div class="col-12 col-sm-6 col-md-4">
        <v-img class="imgbs" :src="img" height="100%" />
      </div>
      <div class="col-12 col-sm-6 col-md-8">
        <v-sheet class="dados">
          <img src="/img/logoins.png" />
          <v-card-title
            class="headline poppins text-uppercase font-weight-bold d-flex justify-center"
            >{{ title }}</v-card-title
          >
          <v-card-text class="b text-justify" v-html="textsubr"></v-card-text>
        </v-sheet>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["title", "text", "to", "img"],
  computed: {
    textsubr() {
      return this.text.substr(0, 200);
    },
  },
};
</script>
<style>
.institutionalcard img {
  max-width: 100%;
}
.institutionalcard .dados {
  text-align: center;
  display: inline-block;
}
.institutionalcard .dados .a {
  text-align: left;
}
.institutionalcard .dados .b {
  text-align: justify;
  font-weight: bold;
}
</style>
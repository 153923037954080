    
<template>
  <v-footer class="main-footer white dark px-0 py-0">
    <div class="img footer fullfooter grey lighten-4">
      <div class="logofooter">
        <img src="/img/logofooter.png" />
      </div>
      <h5 class="center poppins font-weight-bold">
        Av. das Acácias, 1200 - Centro . SINOP - MT <br />Tel.:
        <a href="tel:556635315758">(66)3531-5758</a> / Whatsapp:
        <a
          target="_blank"
          href="https://api.whatsapp.com/send?phone=5566996075758&text=Ol%C3%A1!%20Acessei%20o%20site%20da%20Autoescola%20Real%20e%20queria%20saber%20mais%20sobre%20seus%20servi%C3%A7os."
          >(66) 9 9607-5758</a
        >
      </h5>

      <div class="redes center">
        <ul class="d-inline-block">
          <v-btn
            class="my-2"
            v-for="(s, i) in social"
            :key="i"
            :href="s.link"
            :title="s.link"
            icon
            fab
            small
          >
            <v-icon class="dourado">{{ s.icon }}</v-icon>
          </v-btn>
        </ul>
      </div>
    </div>
    <div class="bt4 px-0 blue-grey darken-4 justify-space-around">
      <div class="white--text a text-center py-2 font-weight-bold">
        {{ name }} © 2020. Todos os direitos reservados.
      </div>
      <a href="https://www.mrxweb.com.br/" target="_blank"
        ><img src="/img/mrx.png" alt="MRX Web Sites" title="MRX Web Sites"
      /></a>
    </div>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    logo: "/img/favicon-white.svg",
  }),
  computed: {
    name() {
      return this.$store.state.holder.name;
    },
    social() {
      return this.$store.state.holder.social;
    },
  },
};
</script>

<style scoped>
@media (max-width: 767px) {
  .bt4 {
    display: inline-block !important;
    text-align: center;
  }
}
.bt4 {
  border-top: 5px solid #e1b768 !important;
  display: flex;
  padding: 12px 0;
  width: 100%;
}
.fullfooter {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.logofooter {
  position: absolute;
  width: 100%;
}
.fullfooter h5 {
  display: inline-block;
  margin-top: 150px;
}
.main-footer {
  border-top: 1px solid;
  border-color: #ccc !important;
}
</style>
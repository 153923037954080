import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Contact from "../views/Contact.vue";
import About from "../views/About.vue";
import Fleet from "../views/Fleet.vue";
import Agreements from "../views/Agreements.vue";
import Licenseone from "../views/Licenseone.vue";
import Licenseprogress from "../views/Licenseprogress.vue";
import Licenserecyclin from "../views/Licenserecyclin.vue";
import Licensedefinitive from "../views/Licensedefinitive.vue";
import Licensechange from "../views/Licensechange.vue";
import Licenserenovation from "../views/Licenserenovation.vue";
import Simulateddetran from "../views/Simulateddetran.vue";
import Simulatedcode from "../views/Simulatedcode.vue";
import Simulatedespecial from "../views/Simulatedespecial.vue";
import Simulatedinternational from "../views/Simulatedinternational.vue";
import Simulatedclass from "../views/Simulatedclass.vue";
import Simulatedstraighten from "../views/Simulatedstraighten.vue";
import Simulated from "../views/Simulated.vue";
import Simulatedmopp from "../views/Simulatedmopp.vue";
import Simulatedtransporte from "../views/Simulatedtransporte.vue";
import Courses from "../views/Courses.vue";
import Course from "../views/Course.vue";
import Cursos from "../views/Cursos.vue";
import Curso from "../views/Curso.vue";
import Test from "../views/Test.vue";
import Account from "../views/Account.vue";
import Register from "../views/Register.vue";
import Download from "../views/Download.vue";
import Arearestrict from "../views/Arearestrict.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Autoescola Real",
    component: Home,
  },
  {
    path: "/contato",
    name: "Contato - Autoescola Real",
    component: Contact,
  },
  {
    path: "/a-empresa/quem-somos",
    name: "Quem Somos - Autoescola Real",
    component: About,
  },
  {
    path: "/a-empresa/nossa-frota",
    name: "Nossa Frota - Autoescola Real",
    component: Fleet,
  },
  {
    path: "/a-empresa/convenios-com-empresas",
    name: "Convênios com Empresas  - Autoescola Real",
    component: Agreements,
  },

  {
    path: "/servicos/1-habilitacao-a-eou-b-61",
    name: "1º Habilitação A e/ou B  - Autoescola Real",
    component: Licenseone,
  },
  {
    path: "/servicos/adicao-de-categoria-a-ou-b-57",
    name: "Adição de Categoría A / B  - Autoescola Real",
    component: Licenseprogress,
  },
  {
    path: "/servicos/atualizacao--reciclagem-60",
    name: "Atualização / Reciclagem  - Autoescola Real",
    component: Licenserecyclin,
  },
  {
    path: "/servicos/cnh-definitiva-59",
    name: "CNH Definitiva  - Autoescola Real",
    component: Licensedefinitive,
  },
  {
    path: "/servicos/mudanca-de-categoria-cde-56",
    name: "Mudança de Categoria C/D/E  - Autoescola Real",
    component: Licensechange,
  },
  {
    path: "/servicos/renovacao-58",
    name: "Renovação  - Autoescola Real",
    component: Licenserenovation,
  },

  {
    path: "/servicos/simulado-detran-67",
    name: "Simulado Detran - Autoescola Real",
    component: Simulateddetran,
  },
  {
    path: "/servicos/codigo-de-transito-br-69",
    name: "Código de Trânsito BR  - Autoescola Real",
    component: Simulatedcode,
  },
  {
    path: "/servicos/cnh-especial-pcd---isencoes-66",
    name: "CNH Especial PCD  - Autoescola Real",
    component: Simulatedespecial,
  },
  {
    path: "/servicos/cnh-internacional-pid-62",
    name: "CNH Internacional  - Autoescola Real",
    component: Simulatedinternational,
  },
  {
    path: "/servicos/aulas-praticas-63",
    name: "Aulas Práticas  - Autoescola Real",
    component: Simulatedclass,
  },
  {
    path: "/servicos/direcao-para-habilitados-64",
    name: "Direção para habilitados  - Autoescola Real",
    component: Simulatedstraighten,
  },
  {
    path: "/servicos/simulador-65",
    name: "Simulador  - Autoescola Real",
    component: Simulated,
  },
  {
    path: "/servicos/provas-e-conteudos-das-materias",
    name: "Provas e Conteúdos das matérias  - Autoescola Real",
    component: Download,
  },
  {
    path: "/area-restrita",
    name: "Minha área restrita  - Autoescola Real",
    component: Arearestrict,
  },
  {
    path: "/cursos",
    name: "Cursos - Autoescola Real",
    component: Cursos,
  },
  {
    path: "/cursos/:curso",
    name: "Cursos - Autoescola Real",
    component: Curso,
  },
  {
    path: "/materias",
    name: "Matérias - Autoescola Real",
    component: Courses,
  },
  {
    path: "/materias/:course",
    name: "Matérias - Autoescola Real",
    component: Course,
  },
  {
    path: "/simulado/:test",
    name: "Simulado - Autoescola Real",
    component: Test,
  },
  {
    path: "/meus-dados",
    name: "Meus dados - Autoescola Real",
    component: Account,
  },
  {
    path: "/cadastro",
    name: "Cadastro - Autoescola Real",
    component: Register,
  },
  {
    path: "/servicos/cursos-mopp-73",
    name: "Cursos MOPP - Autoescola Real",
    component: Simulatedmopp,
  },
  {
    path: "/servicos/transporte-individual-e-coletivo-74",
    name: "Transporte Individual e Coletivo - Autoescola Real",
    component: Simulatedtransporte,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  document.title = to.name;
  window.scrollTo(0, 0);
});

export default router;

<template>
  <v-app>
    <main-header></main-header>

    <v-content>
      <router-view></router-view>
    </v-content>

    <main-footer></main-footer>
  </v-app>
</template>

<script>
import MainHeader from "./components/MainHeader";
import MainFooter from "./components/MainFooter";

export default {
  components: {
    MainHeader,
    MainFooter,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Oswald&family=Poppins&display=swap");
.a,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif !important ;
}
.v-application .headline {
  font-family: "Poppins", sans-serif !important ;
}
nav,
button,
ul {
  font-family: "Poppins", sans-serif !important ;
}

html {
  font-size: 16px;
}
.center {
  text-align: center;
}
.poppins,
.poppins * {
  font-family: "Poppins", sans-serif !important ;
}
main {
  font-size: 0.875rem;
  letter-spacing: 0.25px;
}
ul {
  list-style: none;
  padding: 0;
}
h1 {
  font-size: 3rem;
  letter-spacing: 0px;
}
h2 {
  font-size: 2.125rem;
  text-transform: uppercase;
}
h3 {
  font-size: 1.5rem;
  font-weight: normal;
  letter-spacing: 0px;
  margin-bottom: 12px;
}
h3.primary--text::after {
  background: var(--v-primary-base);
}
h3.white--text::after {
  background: white;
}
h4 {
  font-size: 1.25rem;
  font-weight: medium;
  letter-spacing: 0.15px;
}
h5 {
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 0.15px;
}
h6 {
  font-size: 0.875rem;
  font-weight: medium;
  letter-spacing: 0.1px;
}
.bold {
  font-weight: bold;
}
.v-carousel .fas.fa-chevron-left,
.v-carousel .fas.fa-chevron-right {
  font-size: 26px !important;
}
.v-expansion-panels .fas.fa-chevron-up,
.v-expansion-panels .fas.fa-chevron-down {
  font-size: 18px !important;
}
.simple-slide .v-slide-group__content {
  display: grid;
  grid-auto-flow: column;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 632px;
}
@media screen and (max-width: 600px) {
  .simple-slide .v-slide-group__content {
    grid-auto-columns: calc(100vw - 136px);
  }
}
.white-arrows .fas.fa-chevron-left,
.white-arrows .fas.fa-chevron-right {
  color: white !important;
}

.h-100 {
  height: 100% !important;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.no-link,
.no-link:active,
.no-link:link {
  text-decoration: none;
  color: inherit;
}
</style>

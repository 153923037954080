<template>
  <v-container>
    <h2 align="center">Cursos</h2>
    <div class="articles-list">
      <template v-if="!articles.length">
        <v-skeleton-loader class="mb-4" type="card"></v-skeleton-loader>
        <v-skeleton-loader class="mb-4" type="card"></v-skeleton-loader>
        <v-skeleton-loader class="mb-4" type="card"></v-skeleton-loader>
      </template>
      <template v-else>
        <v-sheet class="mx-auto pt-6">
          <div class="grid5">
            <photos
              v-for="(service, n) in articles"
              :key="n"
              :to="'/cursos/' + service.id"
              :files="files"
              :img="service.img"
              :title="service.title"
            ></photos>
          </div>
        </v-sheet>
      </template>
    </div>
  </v-container>
</template>

<script>
import Photos from "../components/Photos";

export default {
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    articles() {
      return this.$rest("cursos").list;
    },
  },
  beforeCreate() {
    this.$rest("cursos").get();
  },
  components: {
    Photos,
  },
};
</script>

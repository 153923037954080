<template>
  <v-container class="licenseprogress">
    <template v-if="!licenseprogress.text">
      <v-skeleton-loader
        class="my-10 mx-2"
        type="image"
        min-width="330px"
        min-height="200px"
      ></v-skeleton-loader>
      <div>
        <v-skeleton-loader class="mb-4" type="header"></v-skeleton-loader>
        <v-skeleton-loader class="mb-4" type="chip"></v-skeleton-loader>
        <v-skeleton-loader class="mb-4" type="paragraph"></v-skeleton-loader>
        <v-skeleton-loader class="mb-4" type="paragraph"></v-skeleton-loader>
      </div>
    </template>
    <template v-else>
      <templates-institutional
        :img="files + licenseprogress.img"
        :imgverif="licenseprogress.img"
        :title="licenseprogress.title"
        :text="licenseprogress.text"
      ></templates-institutional>
    </template>
  </v-container>
</template>

<script>
import TemplatesInstitutional from "../templates/Institutional";

export default {
  computed: {
    files() {
      return "https://www.autoescolareal.com.br/";
    },
    licenseprogress() {
      return this.$rest("licenseprogress").item;
    },
  },
  beforeCreate() {
    this.$rest("licenseprogress").get(1);
  },
  components: {
    TemplatesInstitutional,
  },
};
</script>
<style>
.licenseprogress img {
  display: inline-block;
  max-width: 100%;
}
.licenseprogress > div {
  display: inline-block;
  width: 100%;
}
</style>
<template>
  <v-container class="py-12">
    <div class="py-4">
      <template v-if="!course">
        <h2>Materias</h2>
        <v-skeleton-loader class="mb-4" type="heading"></v-skeleton-loader>
        <v-skeleton-loader class="mb-4" type="chip"></v-skeleton-loader>
        <v-skeleton-loader class="mb-4" type="image"></v-skeleton-loader>
        <v-skeleton-loader class="mb-4" type="paragraph"></v-skeleton-loader>
        <v-skeleton-loader type="paragraph"></v-skeleton-loader>
      </template>
      <div class="course-post" v-else>
        <h2>{{ course.title }}</h2>
        <div v-html="course.text"></div>
        <v-card-actions>
          <v-btn color="primary" to="/materias" depressed>Voltar</v-btn>
          <v-spacer></v-spacer>
          <v-btn :to="'/simulado/'+id" class="py-4" color="yellow accent-4" depressed>Fazer simulado</v-btn>
        </v-card-actions>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    id() {
      return parseInt(this.$route.params.course);
    },
    course() {
      return this.$rest("courses").item;
    }
  },
  created() {
    const token = window.localStorage.getItem("token");
    if (token) {
      this.$rest("courses").get(this.id);
    } else {
      window.location.href = "/area-restrita";
    }
  }
};
</script>

<style>
.course-post {
  display: inline-block;
  padding: 15px;
  background: #f8f8f8;
  border: 1px solid #dddddd;
}
.course-post > h4 {
  grid-area: title;
}
.course-post > div {
  grid-area: text;
  word-break: break-word;
}
.course .course-list {
  display: grid;
  grid-auto-flow: row;
  gap: 10px;
}
</style>
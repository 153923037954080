<template>
  <div class="linha-center">
    <v-btn class="btnopen" color="primary" @click="open">Ver menu</v-btn>
    <div class="positionabs2" id="openclick">
      <v-container>
        <div class="row">
          <div class="col-12 col-sm-12">
            <div class="posabs">
              <v-menu open-on-hover top offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn large v-bind="attrs" v-on="on">
                    <img src="../../public/img/iconea.png" /> Habilitação
                    <i class="fa fa-chevron-up" aria-hidden="true"></i>
                  </v-btn>
                </template>
                <v-list class="bgred">
                  <v-list-item
                    v-for="(itema, index) in itemsa"
                    :key="index"
                    :to="itema.link"
                  >
                    <v-list-item-title class="branco">{{
                      itema.title
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="col-12 col-sm-12">
            <div class="posabs">
              <v-menu open-on-hover top offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn large v-bind="attrs" v-on="on">
                    <img src="../../public/img/icone2.png" /> Simulados
                    <i class="fa fa-chevron-up" aria-hidden="true"></i>
                  </v-btn>
                </template>
                <v-list class="bgred">
                  <v-list-item
                    v-for="(itemb, index) in itemsb"
                    :key="index"
                    :to="itemb.link"
                  >
                    <v-list-item-title class="branco">{{
                      itemb.title
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="col-12 col-sm-12">
            <div class="posabs">
              <v-menu open-on-hover top offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn large v-bind="attrs" v-on="on">
                    <img src="../../public/img/icone5.png" /> Cursos
                    <i class="fa fa-chevron-up" aria-hidden="true"></i>
                  </v-btn>
                </template>
                <v-list class="bgred">
                  <v-list-item
                    v-for="(itemd, index) in itemsd"
                    :key="index"
                    :to="'/cursos/' + itemd.link"
                  >
                    <v-list-item-title class="branco">{{
                      itemd.title
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="col-12 col-sm-12">
            <div class="posabs">
              <v-menu open-on-hover top offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn large v-bind="attrs" v-on="on">
                    <img src="../../public/img/icone4.png" /> Outros Serviços
                    <i class="fa fa-chevron-up" aria-hidden="true"></i>
                  </v-btn>
                </template>
                <v-list class="bgred">
                  <v-list-item
                    v-for="(itemc, index) in itemsc"
                    :key="index"
                    :to="itemc.link"
                  >
                    <v-list-item-title class="branco">{{
                      itemc.title
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    itemsa: [
      {
        title: "1º Habilitação A e/ou B",
        link: "/servicos/1-habilitacao-a-eou-b-61",
      },
      {
        title: "Adição de Categoria A ou B",
        link: "/servicos/adicao-de-categoria-a-ou-b-57",
      },
      {
        title: "Atualização / Reciclagem",
        link: "/servicos/atualizacao--reciclagem-60",
      },
      { title: "CNH Definitiva", link: "/servicos/cnh-definitiva-59" },
      {
        title: "Mudança de Categoria C/D/E",
        link: "/servicos/mudanca-de-categoria-cde-56",
      },
      { title: "Renovação", link: "/servicos/renovacao-58" },
      {
        title: " CNH Especial (PCD) ISENÇÕES",
        link: "/servicos/cnh-especial-pcd---isencoes-66",
      },
    ],
    itemsb: [
      { title: "Simulado Detran", link: "/servicos/simulado-detran-67" },
      {
        title: "Provas e Conteúdos das Matérias",
        link: "/servicos/provas-e-conteudos-das-materias",
      },
      {
        title: "Código de Trânsito BR",
        link: "/servicos/codigo-de-transito-br-69",
      },
    ],
    itemsc: [
      {
        title: "CNH Internacional",
        link: "/servicos/cnh-internacional-pid-62",
      },
      { title: "Aulas Práticas", link: "/servicos/aulas-praticas-63" },
      {
        title: "Direção para Habilitados",
        link: "/servicos/direcao-para-habilitados-64",
      },
      {
        title: "Transporte individual e coletivo",
        link: "/servicos/transporte-individual-e-coletivo-74",
      },
    ],
  }),
  methods: {
    open() {
      document.getElementById("openclick").style.display = "block";
    },
  },
  computed: {
    itemsd() {
      let cursos = this.$rest("menucursos").list;
      return cursos && cursos.slice(0, 15);
    },
  },
  beforeCreate() {
    this.$rest("menucursos").get();
  },
};
</script>
<style>
.btnopen {
  display: none;
}
.v-menu__content {
  z-index: 99999999 !important;
}
.linha-center {
  display: inline-block;
  width: 100%;
  position: relative;
}
.positionabs2 {
  position: relative;
  top: 0;
  display: block;
}
.bgred {
  background-color: #f00 !important;
}
.bgred > div:hover {
  background: #00000088;
}

@media (max-width: 700px) {
  .btnopen {
    display: block;
  }
  .posabs {
    position: relative;
    top: 0;
  }
  .positionabs2 {
    display: none;
  }
}
.posabs img {
  float: left;
  margin-right: 14px;
}
.posabs i {
  float: right;
  margin-left: 14px;
}
.divnav > img,
.divnav > div,
.divnav > i {
  float: left;
  margin: 0 4px;
}
.divnav > div {
  width: 180px;
}
.posabs button:hover img {
  filter: brightness(0) invert(1);
}
.tdn {
  text-decoration: none;
}
.h70 {
  height: 70px !important;
  width: 100%;
}
</style>

<template>
  <span> {{ time }}</span>
</template>

<script>
import { toLocaleDate } from "@/inputRules";

export default {
  props: {
    value: {
      type: Date,
      required: true,
    },
  },
  data: () => ({
    time: "",
    interval: null,
    past: false,
  }),
  created() {
    this.interval = setInterval(() => {
      if (!this.past && this.value <= new Date()) {
        this.$emit("now");
        this.past = true;
      }

      this.time = toLocaleDate(this.value);
    }, 1000);
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.interval);
    next();
  },
};
</script>

<template>
  <v-hover>
    <v-card class="dasli white darken-1">
      <v-img :aspect-ratio="11 / 9" :src="imgfinal">
        <v-expand-transition>
          <div
            class="d-flex transition-fast-in-fast-out orangebg v-card--reveal"
            style="height: 100%"
          >
            <router-link :to="to">
              <h4 class="titleh3 white--text center px-1">
                {{ title }}
                <br /><small>Clique para ver</small>
              </h4>
            </router-link>
          </div>
        </v-expand-transition>
      </v-img>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: ["title", "text", "to", "img", "files"],
  computed: {
    imgfinal() {
      let image = this.img != null ? this.files + this.img : "../img/fd.jpg";
      return image;
    },
  },
};
</script>

<style>
.titleh3 {
  line-height: 24px !important;
}
.titleh3 small {
  font-size: 12px !important;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.photo-card photo {
  max-width: 336px;
  border-radius: 4px;
  background: #fff;
  display: grid;
  grid-template-rows: 200px auto;
  position: relative;
  padding: 15px;
}

.photo-card img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.photo-card h5 {
  white-space: normal;
}
.photo-card time {
  position: absolute;
  top: 8px;
  left: -4px;
  width: 60px;
  border-radius: 2px;
  display: grid;
  grid-template-rows: 60px 20px;
  padding-left: 2px;
}
.photo-card .photo-card-month {
  line-height: 14px !important;
}
.photo-card .photo-card-day {
  line-height: 18px !important;
}
.orangebg {
  background: #0000008f;
  opacity: 1 !important;
}
.v-application a {
  text-decoration: none !important;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-carousel',{staticClass:"slideprincipal elevation-1 relative",attrs:{"cycle":"","delimiter-icon":"fas fa-minus","hide-delimiters":"","show-arrows-on-hover":""}},[(!_vm.slides.length)?[_c('v-carousel-item',[_c('img',{staticClass:"carousel-img",attrs:{"src":"/img/banners.jpg"}})])]:_vm._l((_vm.slides),function(s,i){return _c('v-carousel-item',{key:i},[_c('img',{staticClass:"carousel-img",attrs:{"src":_vm.files + s.img}})])})],2),_c('div',{staticClass:"linha-center"},[_c('div',{staticClass:"positionabs"},[_c('v-container',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6 col-md-6 col-lg-3"},[_c('div',{staticClass:"posabs"},[_c('v-menu',{attrs:{"open-on-hover":"","top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btnapple",attrs:{"large":""}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":"img/iconea.png"}}),_c('span',[_vm._v("Habilitação")]),_c('i',{staticClass:"fa fa-chevron-up",attrs:{"aria-hidden":"true"}})])]}}])},[_c('v-list',{staticClass:"bgred"},_vm._l((_vm.itemsa),function(itema,index){return _c('v-list-item',{key:index,attrs:{"to":itema.link}},[_c('v-list-item-title',{staticClass:"branco"},[_vm._v(" "+_vm._s(itema.title)+" ")])],1)}),1)],1)],1)]),_c('div',{staticClass:"col-12 col-sm-6 col-md-6 col-lg-3"},[_c('div',{staticClass:"posabs"},[_c('v-menu',{attrs:{"open-on-hover":"","top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btnapple",attrs:{"large":""}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":"img/icone2.png"}}),_vm._v(" "),_c('span',[_vm._v("Simulados")]),_c('i',{staticClass:"fa fa-chevron-up",attrs:{"aria-hidden":"true"}})])]}}])},[_c('v-list',{staticClass:"bgred"},_vm._l((_vm.itemsb),function(itemb,index){return _c('v-list-item',{key:index,attrs:{"to":itemb.link}},[_c('v-list-item-title',{staticClass:"branco"},[_vm._v(" "+_vm._s(itemb.title)+" ")])],1)}),1)],1)],1)]),_c('div',{staticClass:"col-12 col-sm-6 col-md-6 col-lg-3"},[_c('div',{staticClass:"posabs"},[_c('v-menu',{attrs:{"open-on-hover":"","top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btnapple",attrs:{"large":""}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":"img/icone5.png"}}),_vm._v(" "),_c('span',[_vm._v("Cursos")]),_c('i',{staticClass:"fa fa-chevron-up",attrs:{"aria-hidden":"true"}})])]}}])},[_c('v-list',{staticClass:"bgred"},_vm._l((_vm.itemsd),function(itemd,index){return _c('v-list-item',{key:index,attrs:{"to":'cursos/' + itemd.link}},[_c('v-list-item-title',{staticClass:"branco"},[_vm._v(" "+_vm._s(itemd.title)+" ")])],1)}),1)],1)],1)]),_c('div',{staticClass:"col-12 col-sm-6 col-md-6 col-lg-3"},[_c('div',{staticClass:"posabs"},[_c('v-menu',{attrs:{"open-on-hover":"","top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btnapple",attrs:{"large":""}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":"img/icone4.png"}}),_vm._v(" "),_c('span',[_vm._v("Outros Serviços")]),_c('i',{staticClass:"fa fa-chevron-up",attrs:{"aria-hidden":"true"}})])]}}])},[_c('v-list',{staticClass:"bgred"},_vm._l((_vm.itemsc),function(itemc,index){return _c('v-list-item',{key:index,attrs:{"to":itemc.link}},[_c('v-list-item-title',{staticClass:"branco"},[_vm._v(" "+_vm._s(itemc.title)+" ")])],1)}),1)],1)],1)])])])],1)]),_c('v-container',[_c('section',{staticClass:"aboutdiv"},[(!_vm.about.text)?[_c('v-skeleton-loader',{staticClass:"my-10 mx-2",attrs:{"type":"image","min-width":"330px","min-height":"200px"}}),_c('div',[_c('v-skeleton-loader',{staticClass:"mb-4",attrs:{"type":"header"}}),_c('v-skeleton-loader',{staticClass:"mb-4",attrs:{"type":"chip"}}),_c('v-skeleton-loader',{staticClass:"mb-4",attrs:{"type":"paragraph"}}),_c('v-skeleton-loader',{staticClass:"mb-4",attrs:{"type":"paragraph"}})],1)]:[_c('institutional-card',{attrs:{"to":'/a-empresa/quem-somos',"img":_vm.files + _vm.about.img,"title":_vm.about.title,"text":_vm.about.text}})]],2)]),_c('div',{staticClass:"bannerfull center"},[_c('router-link',{attrs:{"to":'/a-empresa/nossa-frota'}},[_c('h3',[_vm._v("CONHEÇA NOSSSA FROTA")]),_c('div',{staticClass:"maxw1"},[_c('img',{attrs:{"src":"/img/auto-escola-capa.jpg"}})]),_c('div',{staticClass:"maxw2"},[_c('img',{attrs:{"src":"/img/imgbg2.jpg"}})])])],1),_vm._m(0),_c('v-container',[[_c('v-sheet',{staticClass:"mx-auto"},[_c('div',{staticClass:"grid5"},_vm._l((_vm.services),function(service,n){return _c('photos',{key:n,attrs:{"to":'/servicos/' + service.link,"files":_vm.files,"img":service.img,"title":service.title}})}),1)])]],2),_c('iframe',{staticClass:"map",attrs:{"src":_vm.map,"allowfullscreen":"","aria-hidden":"false","tabindex":"0"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title center"},[_c('h3',[_vm._v("SERVIÇOS MAIS PROCURADOS")])])}]

export { render, staticRenderFns }
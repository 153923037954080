<template>
  <div>
    <div>
      <v-form v-model="validity" ref="contact" class="form-contact-us">
        <v-text-field
          outlined
          dense
          name="name"
          :rules="[rules.required]"
          v-model="form.name"
          type="name"
          label="Nome"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          name="email"
          :rules="[rules.required, rules.email]"
          v-model="form.email"
          type="email"
          label="E-mail"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          name="phone"
          :rules="[rules.required, rules.phone]"
          v-model="form.phone"
          v-mask="['(##) ####-####', '(##) #####-####']"
          type="tel"
          label="Telefone"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          name="subject"
          :rules="[rules.required]"
          v-model="form.subject"
          type="text"
          label="Assunto"
        ></v-text-field>
        <v-textarea
          outlined
          dense
          name="message"
          :rules="[rules.required]"
          v-model="form.message"
          type="text"
          label="Mensagem..."
        ></v-textarea>
        <v-btn
          @click="submit"
          large
          class="primary darken-1 white--text"
          depressed
          >Enviar</v-btn
        >

        <v-alert
          class="contact-alert"
          v-if="success"
          :icon="false"
          type="success"
          style="font-size: 14px"
        >
          Sua mensagem foi enviada com sucesso.
        </v-alert>

        <v-alert
          class="contact-alert"
          v-if="error"
          :icon="false"
          type="error"
          style="font-size: 14px"
        >
          Aconteceu um erro ao enviar a mensagem.
        </v-alert>
      </v-form>
    </div>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  props: ["action"],
  data: () => ({
    validity: false,
    form: {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    },
    rules: {
      required: (v) => !!v || "Requerido.",
      email: (v) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail inválido.",
      phone: (v) =>
        /\([0-9]{2}\) [0-9]{4,5}-[0-9]{4}/.test(v) || "Telefone inválido.",
    },
    loading: false,
    error: false,
    success: false,
  }),
  methods: {
    submit() {
      if (this.$refs.contact.validate()) {
        this.loading = true;
        this.error = false;
        this.success = false;

        fetch(this.action, {
          method: "POST",
          body: new FormData(this.$refs.contact.$el),
        })
          .then((r) => {
            if (r.ok) this.success = true;
            else throw new Error(r.status);
          })
          .catch(() => (this.error = true))
          .finally(() => (this.loading = false));
      }
    },
  },
  directives: {
    mask,
  },
};
</script>

<style>
.form-contact-us {
  background: white;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  border-radius: 7px;
}
@media screen and (min-width: 960px) {
  .form-contact-us {
    grid-template-columns: 1fr 1fr;
  }
  .form-contact-us :nth-child(5),
  .form-contact-us :nth-child(6),
  .contact-alert {
    grid-column-end: span 2;
  }
}
.form-contact-us .v-alert {
  margin-top: 16px;
  font-size: 18px;
}
</style>
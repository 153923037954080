<template>
  <v-container class="py-12">
    <div class="">
      <h5>
        Tempo para finalizar prova:
        <count :value="'18:20'"></count>
      </h5>
    </div>
    <div class="py-4 center">
      <v-card max-width="700" class="ib mb-5">
        <v-tabs v-model="tab" background-color="primary" dark>
          <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
          <v-tab
            v-for="(item, tab) in testsimulated"
            :key="item.id"
            :href="'#tab-' + tab"
            >{{ tab + 1 }}</v-tab
          >
        </v-tabs>

        <v-tabs-items v-model="tab" class="px-4 py-4">
          <v-tab-item
            v-for="(item, tab) in testsimulated"
            :key="item.id"
            :value="'tab-' + tab"
          >
            <v-card flat class="center">
              <h3>{{ tab + 1 }}. {{ item.title }}</h3>
              <img
                v-if="item.img"
                :src="'https://www.autoescolareal.com.br/' + item.img"
                class="imgfw"
              />
              <v-radio-group
                v-model="radioGroup[tab]"
                @change="(v) => answer(item.id, v)"
              >
                <v-radio
                  v-if="item.r1"
                  :label="'A. ' + item.r1"
                  value="A"
                ></v-radio>
                <v-radio
                  v-if="item.r2"
                  :label="'B. ' + item.r2"
                  value="B"
                ></v-radio>
                <v-radio
                  v-if="item.r3"
                  :label="'C. ' + item.r3"
                  value="C"
                ></v-radio>
                <v-radio
                  v-if="item.r4"
                  :label="'D. ' + item.r4"
                  value="D"
                ></v-radio>
                <v-radio
                  v-if="item.r5"
                  :label="'E. ' + item.r5"
                  value="E"
                ></v-radio>
              </v-radio-group>
              <v-btn
                v-if="total > tab + 1"
                class="py-4"
                color="primary"
                @click="verifquestion(item.id, tab)"
                depressed
                >Continuar</v-btn
              >
              <v-btn
                v-else
                class="py-4"
                color="primary"
                @click="sendquestion(item.id, tab)"
                depressed
                >Finalizar</v-btn
              >
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <div class="py-4 center">
        <v-btn :to="'/materias/' + id" class="py-4" depressed>Voltar</v-btn>
      </div>
      <v-alert
        v-model="alert"
        dismissible
        close-icon="mdi-delete"
        color="red"
        border="left"
        elevation="2"
        colored-border
        icon="fa fa-exclamation-triangle"
        class="alert-float"
      >
        Antes de continuar
        <strong>por favor</strong> responda a questão!
      </v-alert>

      <v-dialog v-model="dialog" max-width="290">
        <v-card>
          <v-card-title class="headline">RESULTADOS</v-card-title>

          <v-card-text>
            Você respondeu
            <strong>{{ percent }}%</strong> das perguntas corretamente.
          </v-card-text>
          <v-card-text>
            Você respondeu
            <strong>{{ correct }}</strong> perguntas corretamente de
            <strong>{{ total }}</strong
            >.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn color="green darken-1" text @click="dialog = false">Ver respostas</v-btn> -->
            <v-btn color="green darken-1" text @click="dialog = false"
              >Fechar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import Count from "../components/Count";

export default {
  data: () => ({
    tab: null,
    radioGroup: [],
    pos: "",
    answers: [],
    alert: false,
    dialog: false,
    correct: 0,
    percent: 0,
  }),
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    id() {
      return parseInt(this.$route.params.test);
    },
    testsimulated() {
      return this.$rest("test").item;
    },
    total() {
      return this.testsimulated.length;
    },
  },
  methods: {
    verifquestion(id, pos) {
      let d = this.radioGroup[pos] ? true : false;
      if (d === false) {
        this.alert = true;
      } else {
        this.alert = false;
        this.tab = "tab-" + (pos + 1);
      }
    },
    sendquestion(id, pos) {
      let d = this.radioGroup[pos] ? true : false;
      if (d === false) {
        this.alert = true;
      } else {
        this.alert = false;
        this.dialog = true;
        let dd = this.answers.filter((valores) => {
          return valores.resposta === "t";
        });
        this.correct = dd.length;
        this.percent = ((dd.length / this.total) * 100).toFixed(2);
      }
    },
    answer(id, v) {
      let resposta = this.testsimulated.filter((valores) => {
        return valores.id === id;
      });
      let r_f = resposta[0].r === v ? "t" : "f";
      this.answers[id] = { v: v, resposta: r_f };
    },
  },
  created() {
    const token = window.localStorage.getItem("token");
    if (token) {
      this.$rest("test").get(this.id);
    } else {
      window.location.href = "/area-restrita";
    }
  },
  components: {
    Count,
  },
  mounted() {},
};
</script>

<style>
.center {
  text-align: center;
}
.ib {
  display: inline-block;
}
.test-post {
  display: inline-block;
  padding: 15px;
  background: #f8f8f8;
  border: 1px solid #dddddd;
}
.test-post > h4 {
  grid-area: title;
}
.test-post > div {
  grid-area: text;
  word-break: break-word;
}
.test .test-list {
  display: grid;
  grid-auto-flow: row;
  gap: 10px;
}
.imgfw {
  max-width: 100%;
}
.alert-float {
  position: fixed;
  top: 5%;
  right: 1%;
  z-index: 999999999;
}
</style>

import { render, staticRenderFns } from "./MainFooter.vue?vue&type=template&id=3e3ad88c&scoped=true&"
import script from "./MainFooter.vue?vue&type=script&lang=js&"
export * from "./MainFooter.vue?vue&type=script&lang=js&"
import style0 from "./MainFooter.vue?vue&type=style&index=0&id=3e3ad88c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e3ad88c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VFooter,VIcon})

<template>
  <div class="contact my-10">
    <v-container class="my-10 update">
      <v-card class="box-form py-2 px-4">
        <h2 class="my-2 center">Meus dados</h2>

        <v-form v-model="validity" ref="update">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.name"
                name="name"
                type="text"
                label="Nome"
                hide-details
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.cpf"
                name="cpf"
                type="text"
                label="CPF"
                hide-details
                outlined
                v-mask="['###.###.###-##', '###.###.###-##']"
                :value="user.cpf"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.email"
                name="email"
                type="text"
                label="Email"
                hide-details
                outlined
                :value="user.email"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.password"
                name="password"
                type="password"
                label="Senha"
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col class="my-2 text-right">
              <v-btn color="primary" @click="send">Atualizar</v-btn>
            </v-col>
          </v-row>
          <v-text-field v-model="form.id" hide-details name="code" type="hidden"></v-text-field>
          <v-alert
            v-model="alert"
            dismissible
            close-icon="mdi-delete"
            color="green"
            border="left"
            elevation="2"
            colored-border
            icon="fa fa-exclamation-triangle"
            class="alert-float"
          >
            Seus dados foram
            <strong>atualizados</strong> com sucesso!
          </v-alert>
          <v-alert
            class="contact-alert"
            v-if="error"
            :icon="false"
            type="error"
            style="font-size: 14px;"
          >Aconteceu um erro ao enviar a mensagem.</v-alert>
        </v-form>
        <v-overlay :value="loading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  data: () => ({
    validity: false,
    form: { id: "", name: "", email: "", cpf: "", password: "" },
    rules: {
      required: v => !!v || "Requerido.",
      email: v =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail inválido."
    },
    loading: false,
    error: false,
    alert: false
  }),
  computed: {
    server() {
      return this.$store.state.server;
    },
    social() {
      return this.$store.state.holder.social;
    },
    map() {
      return this.$store.state.holder.map;
    },
    user() {
      return this.$rest("user").item;
    }
  },
  methods: {
    send() {
      if (this.$refs.update.validate()) {
        this.loading = true;
        this.error = false;

        fetch(this.$store.state.server + "/v1/user", {
          method: "POST",
          body: new FormData(this.$refs.update.$el)
        })
          .then(r => {
            if (r.ok) this.alert = true;
            else throw new Error(r.status);
          })
          .catch(() => (this.error = true))
          .finally(() => (this.loading = false));
      }
    }
  },
  components: {},
  beforeCreate() {
    const code = parseInt(window.localStorage.getItem("code"), 10);
    this.$rest("user").get(code);
  },
  created() {
    this.form.name = this.user.name;
    this.form.cpf = this.user.cpf;
    this.form.email = this.user.email;
    this.form.id = this.user.id;
  },
  directives: {
    mask
  }
};
</script>

<style>
.update {
  margin: 0 auto;
  width: 350px;
}
</style>